@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

@layer utilities {
	.text-fill-transparent {
		-webkit-text-fill-color: transparent;
	}
}

html,
body {
	max-width: 100vw;
	overflow-x: clip;
}

.lock-scroll {
	@apply overflow-y-hidden;
}

/* scroll only */
@media screen(-xl) {
	.lock-scroll--nav {
		@apply overflow-hidden;
	}
}

:is(h1, h2, h3, h4, h5, h6) {
	@apply font-inter tracking-tight;
}

:is(p, li, a) {
	@apply font-inter;
}

:is(p) {
	@apply whitespace-pre-line tracking-tight supports-[not(overflow-wrap:anywhere)]:[word-break:normal] supports-[overflow-wrap:anywhere]:[overflow-wrap:anywhere];
}

:is(svg) {
	@apply h-full;
}

/* once quotescore results is gone - the footer would be the only place this is used */
.gradient-nightmare {
	/* Gradients */
	/* Radial gradient of transparent to rebecca purple */
	background: 
	/* blue to transparent */
		radial-gradient(
			ellipse at center,
			rgba(0, 0, 255, 0.67) 0%,
			transparent 67%
		),
		/* red to transparent */
			radial-gradient(
				ellipse at center,
				rgba(255, 0, 0, 0.67) 0%,
				transparent 67%
			),
		/* yellow to transparent */
			radial-gradient(
				ellipse at center,
				rgba(255, 255, 0, 0.67) 0%,
				transparent 67%
			),
		/* purple to transparent */
			radial-gradient(
				ellipse at center,
				rgba(102, 51, 153, 0.67) 0%,
				transparent 67%
			),
		/* orange to transparent */
			radial-gradient(
				ellipse at center,
				rgba(255, 165, 0, 0.67) 0%,
				transparent 67%
			),
		/* pink to transparent */
			radial-gradient(
				ellipse at center,
				rgba(255, 0, 255, 0.67) 0%,
				transparent 67%
			);
	background-repeat: repeat-x;
	background-size:
		800% 800%,
		800% 800%,
		800% 800%,
		800% 800%,
		800% 800%,
		800% 800%;
	animation: gradient 25s linear infinite;
}

/* More than 3% on the y axis will hit the top of the div. */
@keyframes gradient {
	/* Hide everything */
	/* background-position: 0% 3%, -120% 3%, 110% 3%, 0% 3%, -110% 4%, 0% 2%; */
	/* Show everything */
	/* background-position: 50% 3%, 50% 3%, 50% 3%, 50% 3%, 50% 4%, 50% 2%; */
	/* is blue */
	0% {
		/*					blue,    red,      yellow, purple, orange, pink	*/
		background-position:
			50% 3%,
			-120% 3%,
			110% 3%,
			0% 3%,
			-110% 4%,
			0% 2%;
	}
	/* transition from blue to red */
	10% {
		background-position:
			60% 3%,
			-90% 3%,
			110% 3%,
			0% 3%,
			-110% 4%,
			0% 2%;
	}
	/* is red */
	16.667% {
		background-position:
			110% 3%,
			-40% 3%,
			110% 3%,
			0% 3%,
			-110% 4%,
			0% 2%;
	}
	/* transition red yellow */
	25% {
		background-position:
			110% 3%,
			-50% 3%,
			75% 3%,
			0% 3%,
			-110% 4%,
			0% 2%;
	}
	/* is yellow */
	33.333% {
		background-position:
			110% 3%,
			0% 3%,
			65% 3%,
			0% 3%,
			-110% 4%,
			0% 2%;
	}
	/* transition yellow to purple */
	45% {
		background-position:
			110% 3%,
			0% 3%,
			40% 3%,
			60% 3%,
			-110% 4%,
			0% 2%;
	}
	/* is purple */
	50% {
		background-position:
			110% 3%,
			0% 3%,
			0% 3%,
			50% 3%,
			-110% 4%,
			0% 2%;
	}
	/* tranisition purple to orange */
	60% {
		background-position:
			110% 3%,
			0% 3%,
			0% 3%,
			30% 3%,
			-60% 4%,
			0% 2%;
	}
	/* is orange */
	66.667% {
		background-position:
			110% 3%,
			0% 3%,
			0% 3%,
			110% 3%,
			-50% 4%,
			0% 2%;
	}
	/* transition orange to pink */
	75% {
		background-position:
			110% 3%,
			0% 3%,
			0% 3%,
			110% 3%,
			30% 4%,
			45% 2%;
	}
	/* is pink */
	83.333% {
		background-position:
			110% 3%,
			0% 3%,
			0% 3%,
			110% 3%,
			0% 4%,
			50% 2%;
	}
	/* transition pink to blue */
	93.6% {
		background-position:
			38% 3%,
			-120% 3%,
			110% 3%,
			0% 3%,
			-110% 4%,
			67% 2%;
	}
	/* is blue */
	100% {
		background-position:
			50% 3%,
			-120% 3%,
			110% 3%,
			0% 3%,
			-110% 4%,
			0% 2%;
	}
}

.bg-powerpoint-gradient {
	background-image: linear-gradient(
		164deg,
		theme('colors.royal-orange') 6%,
		theme('colors.raspberry-pink') 37%,
		theme('colors.vivid-cerulean') 120%
	);
}

.fancy-bg {
	background-image: url('/img/mega-man-homepage/background_mobile.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

@media screen(sm) {
	.fancy-bg {
		background-image: image-set(
			url('/img/mega-man-homepage/background.webp') 1x,
			url('/img/mega-man-homepage/background@2x.webp') 2x
		);
	}
}

/**
 *  Updated dims for grid system:
 * 		360px - 4c, margin 16px, gutter 20px
 * 		640px - sm - 8c, margin 24px, gutter 32px
 * 		768px - md - 8c, margin 24px, gutter 32px
 * 		1024px - lg - 10c, margin 48px, gutter 32px
 * 		1440px - 2xl - 12c, margin 80px, gutter 32px
 */
.grid-layout {
	@apply grid grid-cols-4 sm:grid-cols-8 lg:grid-cols-10 2xl:grid-cols-12;
	@apply gap-5 sm:gap-8;
	@apply mx-4 sm:mx-6 lg:mx-12 2xl:mx-auto 2xl:max-w-7xl;
}

.hvac-container {
	@apply w-full max-w-7xl px-4 md:px-6 lg:mx-auto 2xl:px-0;
}
